
Vue.component('badgesComponent',{
    template:`<div>
                  <headerSectionComponent></headerSectionComponent>
                  <!--<registerComponent></registerComponent>-->
                  <loginComponent></loginComponent>
                  <changePasswordModalComponent></changePasswordModalComponent>
                  <div class="section white">
                      <div class="container">
                          <div class="row">
                              <div class="left sticky-component items-category-tree">
                                  <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                              </div>
                              <div class="col m8 s12">
                                  <carModalComponent></carModalComponent>
                              </div>
                              <div class="col m2 s12 sticky-component home-right-column">
                                  <userSectionComponent></userSectionComponent>
                                  <carComponent></carComponent>
                              </div>
                          </div>
                      </div>
                  </div>
                <footerSectionComponent></footerSectionComponent>
            </div>`,
    mounted:function(){
        this.$nextTick(function () {
            $(".dropdown-button").dropdown();
            $('select').material_select();
            //$('head script[src*="unveil.js"]').remove();
        });
    },
    methods: {
        loadCategories: function (root = null, lvl = 0) {
            this.$store.dispatch('loadCategories', root, lvl);
        },
        selectCategory: function (category) {
            if (category) {
                this.loadItems(category.code);
                console.log("updateCategory select category",category)
                this.$router.push('/category/'+category.Name);
                this.$store.dispatch('updateCategory', category);
            }else {
                this.$store.dispatch('updateCategory', {});
                this.loadItems();
            }
        },

    }
});
